import React from "react";
import loadable from "@loadable/component";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import { TinaProvider, TinaCMS } from "tinacms";
import { S3MediaStore } from "./react-tinacms-s3";
import PrivateRoute from "./PrivateRoute";
import ReactGA from "react-ga4";
import { AuthProvider } from "./components/authentication/Auth";
import LoadingOverlay from "components/adminTable/loadingOverlay";
//import { MarkdownFieldPlugin } from 'react-tinacms-editor';

//lazyload router components using @loadable/component
function importLoadable(imp) {
  return loadable(imp, {
    fallback: <LoadingOverlay />,
  });
}

//routes
const Login = importLoadable(() => import("components/authentication/Login"));
const RequestInvite = importLoadable(() =>
  import("components/authentication/RequestInvite")
);
const Request = importLoadable(() =>
  import("components/authentication/Requests")
);
const SelfOnboarding = importLoadable(() =>
  import("components/authentication/SelfOnboarding")
);
const CompleteAccount = importLoadable(() =>
  import("components/authentication/CompleteAccount")
);
const UpdateAccount = importLoadable(() =>
  import("components/authentication/UpdateAccount")
);
const RequestMentor = importLoadable(() =>
  import("components/authentication/RequestMentor")
);
const SignUp = importLoadable(() =>
  import("components/authentication/Signup_new")
);
const LostPassword = importLoadable(() =>
  import("components/authentication/LostPassword")
);
const PortalApp = importLoadable(() =>
  import("components/authentication/portalApp")
);
const Portal = importLoadable(() => import("components/authentication/Portal"));
const Account = importLoadable(() => import("components/account/Account"));
const CampaignDetail = importLoadable(() =>
  import("components/companies/companyDetail")
);
const CompanyOverview = importLoadable(() =>
  import("components/companies/companyOverview")
);
const InvestorDetail = importLoadable(() =>
  import("components/investors/investorDetail")
);
const FounderProfile = importLoadable(() =>
  import("components/founders/founderDetail")
);
const FounderOverview = importLoadable(() =>
  import("components/founders/founderOverview")
);
const InvestorOverview = importLoadable(() =>
  import("components/investors/investorOverview")
);
const AdminPanel = importLoadable(() =>
  import("components/adminTable/adminPanel")
);
const NewTable = importLoadable(() =>
  import("components/adminTable/newAdminTable")
);
const Marketplace = importLoadable(() => import("components/marketplace"));
const MarketplaceProfile = importLoadable(() =>
  import("components/marketplaceProfile")
);
const MarketplaceCategory = importLoadable(() =>
  import("components/marketplaceCategory")
);
const NewsOverview = importLoadable(() =>
  import("components/startupNews/NewsOverview")
);
const PostPage = importLoadable(() =>
  import("components/startupNews/PostPage")
);
const Dashboard = importLoadable(() =>
  import("components/dashboard/dashboard")
);
const Home = importLoadable(() => import("components/frontPage/home"));
// const InvitedSignUp = importLoadable(() =>
//   import("components/authentication/InvitedSignUp")
// );

function App() {
  ReactGA.initialize("G-NCJDGFE0TN");
  ReactGA.send("pageview");

  const mediaStore = new S3MediaStore({});

  const cms = new TinaCMS({
    enabled: false,
    sidebar: true,
    media: mediaStore,
  });
  //cms.plugins.add(MarkdownFieldPlugin)
  const history = useHistory();

  return (
    <AuthProvider>
      <Router history={history}>
        <TinaProvider cms={cms}>
          <Route exact path="/login" component={Login} />
          <Route exact path="/request-invite" component={RequestInvite} />
          <Route exact path="/request" component={Request} />
          <Route exact path="/self-onboarding" component={SelfOnboarding} />
          <Route exact path="/complete-account" component={CompleteAccount} />
          <Route exact path="/update-account" component={UpdateAccount} />
          <Route exact path="/request-mentor" component={RequestMentor} />

          <Route exact path="/signup/:id" component={SignUp} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/resetpassword" component={LostPassword} />

          <PrivateRoute exact path="/appportal" component={PortalApp} />
          <PrivateRoute exact path="/portal" component={Portal} />
          <PrivateRoute exact path="/my-account/" component={Account} />
          <PrivateRoute
            exact
            path="/investment-campaigns/:id"
            component={CampaignDetail}
          />
          <PrivateRoute
            exact
            path="/campaigns/:sector"
            component={CompanyOverview}
          />
          <PrivateRoute
            exact
            path="/investors/:id"
            component={InvestorDetail}
          />
          <PrivateRoute exact path="/founders/:id" component={FounderProfile} />
          <PrivateRoute exact path="/founders" component={FounderOverview} />
          <PrivateRoute exact path="/investors" component={InvestorOverview} />
          <PrivateRoute exact path="/admin-panel" component={AdminPanel} />
          <PrivateRoute exact path="/new-panel" component={NewTable} />
          <PrivateRoute exact path="/marketplace" component={Marketplace} />
          <PrivateRoute
            exact
            path="/marketplace-profile"
            component={MarketplaceProfile}
          />
          <PrivateRoute
            exact
            path="/marketplace-category/:id"
            component={MarketplaceCategory}
          />
          <PrivateRoute exact path="/startup-news" component={NewsOverview} />
          <PrivateRoute exact path="/startup-news/:id" component={PostPage} />
          <PrivateRoute
            exact
            path="/investor-dashboard"
            component={Dashboard}
          />
          <PrivateRoute exact path="/" component={Home} />
        </TinaProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
